<template lang="pug">
div.body
  //- van-swipe.my-swipe(:autoplay='3000', indicator-color='white')
    van-swipe-item(v-for="(swipe,index) of swiperData" :key="index") 
      | {{ swipe.text }}
    van-col
  .content 
    van-row(v-for='(item, index) in imageList' key='index' type="flex" justify="center")
      van-col
        //- h3 {{item.title}}
        h3 家具行业互联网解决方案
        img.img(v-lazy='item.src' :key='item.src')
        h4 3分钟搭建移动商城
        | 深挖行业痛点 匹配产品功能 提供家具行业电商解决方案
  h3.content  品牌列表    
  van-grid(:column-num="2" icon-size='120px' )
    van-grid-item(v-for='(com, comIndex) in companys' :key="comIndex"
      :icon='com.com_logo'
      :text='com.com_name'
      :url='com.route_abb')
        //- van-image.img(height='60' :src='com.com_id_poi_companys.com_logo' fit='contain')
  van-button.footer(type='info' block @click="show = true") 我也要开云展厅
  van-dialog(
    v-model='show'
    title="请联系我们"
    )
    van-field(
      v-for="(item, conIndex) of contact" :key="conIndex"
      :label="item.label"
      :value="item.value"
      disabled
    )
      template(v-if="item.type === 'image'" #input)
        van-image(
          width="100"
          height="100"
          :src="item.src"
          )

</template>

<script>
import { Divider } from 'vant'

export default {
  components: {
    [Divider.name]: Divider
  },
  data() {
    return {
      swiperData: [
        {
          text: '云商城'
        },
        {
          text: '云展厅'
        },
        {
          text: '云报备'
        },
        {
          text: '云分销'
        }
      ],
      show: false,
      contact: [
        {
          label: '电话',
          value: '18911192268'
        },
        {
          label: '微信',
          type: 'image',
          src: '/images/contact/contact_qrcode.jpg'
        },
        {
          label: '邮箱',
          value: 'l@dpjia.com'
        }
      ],
      imageList: [
        {
          title: '搭配家微商城',
          src: '/images/default_bg.png'
        }
      ],
      companys: []    // 公司列表
    }
  },
  methods: {
    async getCompaynList() {
      // let params = {
      //   with: {
      //     include:[
      //         {
      //             table:"com_id_poi_companys",
      //             keys:"com_name,com_id,com_order,full_name,com_logo"
      //         }
      //     ],
      //   },
      //   where: {
      //     com_id_poi_companys: {
      //       $gt: 0
      //     },
      //     switch_state: 'on',
      //   },
      //   keys: "id,route_abb,com_id_poi_companys,order",
      //   needcount: 0,
      //   order: '-order'
      // }
      let res = await this.$api.GetComBrandsList()
      this.companys = res.data
    } 
  },
  mounted() {
    this.getCompaynList()
  } 
}
</script>

<style scoped>
.my-swipe .van-swipe-item {
  color: #fff;
  font-size: 20px;
  line-height: 200px;
  text-align: center;
  background-color: #39a9ed;
}
.body {
  margin-bottom: 60px;
}
.content{
  padding: 0 20px;
}
.img {
  width:100%
}
.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  z-index: 1000;
}
</style>